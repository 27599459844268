const { getCategory } = require('./CategoryService')

async function generateBreadCrumbs({ categoryID, item = false } = {}) {
  let breadCrumbs = []
  breadCrumbs.push({ page: 'index', title: 'Uitleendienst' })

  if (categoryID) {
    const categoryBreadCrumbs = await categoryToBreadCrumbs({
      categoryID,
      item
    })
    breadCrumbs = breadCrumbs.concat(categoryBreadCrumbs)
  }

  return breadCrumbs
}

async function categoryToBreadCrumbs({ categoryID, item = false } = {}) {
  let breadCrumbs = []
  const currentCategory = await getCategory({ categoryID, slug: true })
  const parentCategory = await getCategory({
    categoryID: currentCategory.ParentCategoryID,
    slug: false
  })

  if (currentCategory.ParentCategoryID) {
    if (item) {
      await breadCrumbs.push({
        title: capitalizeFirstLetter(currentCategory.Description),
        path: `/catalogus/${parentCategory.Slug}/${currentCategory.Slug}`
      })
    }

    await breadCrumbs.push({
      title: parentCategory.Description,
      path: `/catalogus/${parentCategory.Slug}`
    })
  } else {
    await breadCrumbs.push({
      page: 'categories',
      title: capitalizeFirstLetter(currentCategory.Description),
      params: { categoryID: currentCategory.CategoryID }
    })
  }

  return breadCrumbs.reverse()
}

function capitalizeFirstLetter(string) {
  if (string) return string.charAt(0).toUpperCase() + string.slice(1)
  return null
}

module.exports = { generateBreadCrumbs }
