<template>
  <div class="card card-product mt-5" @click="toItem">
    <div v-if="item.Type != 'Composition'" class="card-img-top p-4">
      <b-img
        v-if="imageUrl"
        fluid
        crossorigin="anonymous"
        class="rounded"
        :src="imageUrl"
        :alt="item.Description"
      />
      <b-img
        v-else
        fluid
        class="d-block mx-auto"
        alt="Afbeelding niet gevonden"
        src="/img/itemFallback.png"
      />
    </div>
    <div v-if="item.Type === 'Composition'" class="card-img-top p-4">
      <ImageGrid :images="compositionImages" :alt="item.Description" />
    </div>
    <div class="card-body text-left">
      <h3 class="product-title font-size-sm">
        <router-link
          :to="{
            path: routePath
          }"
          >{{ item.Description }}</router-link
        >
      </h3>
      <p class="itemDescription" v-html="item.MediumDescription" />
    </div>
    <div class="card-footer">
      <router-link
        :to="{
          path: routePath
        }"
        class="btn btn-primary btn-block mb-2"
        type="button"
      >
        <i class="czi-cart font-size-sm mr-1"></i>Details en aanvragen
      </router-link>
    </div>
  </div>
</template>

<script>
// @ is an alias to /src
import ImageGrid from '@/components/items/ImageGrid'
export default {
  name: 'Item',
  components: {
    ImageGrid
  },
  props: {
    item: {
      type: Object,
      default: function() {
        return {}
      }
    },
    suggestedItem: {
      type: Boolean,
      required: false,
      default: function() {
        return false
      }
    }
  },

  computed: {
    compositionImages: function() {
      if (this.item.Type === 'Composition') {
        let images = []
        let filteredImages = this.item.Items?.filter(function(item) {
          return item.DefaultImage
        })
        if (filteredImages) {
          images = filteredImages.map(
            item =>
              process.env.VUE_APP_URL +
              (item.DefaultImage ? item.DefaultImage.ImageUrl : '')
          )
        }

        if (images.length < 1) {
          images = this.getImageUrlsFromItemList(this.item.ItemIDs.split(','))
        }
        return images
      }
      return null
    },

    imageUrl: function() {
      if (
        this.item.Images != undefined &&
        this.item.Images[0] &&
        this.item.Images[0].ImageUrl
      ) {
        return process.env.VUE_APP_URL + this.item.Images[0].ImageUrl
      } else if (this.item.Type === 'Package') {
        return `${process.env.VUE_APP_URL}/Handlers/ImageHandler.ashx?TableName=Rental.Item&CUST_PackageID=${this.item.ID}`
      } else {
        return `${process.env.VUE_APP_URL}/Handlers/ImageHandler.ashx?TableName=Rental.Item&ItemID=${this.item.ID}`
      }
    },
    routePath: function() {
      if (this.suggestedItem) return `/catalogus/artikel/${this.item.Slug}`
      return `${this.$route.path}/artikel/${this.item.Slug}`
    }
  },
  created: function() {
    if (this.item.PackageID) {
      this.item.ID = this.item.PackageID
    }
  },
  methods: {
    getImageUrlsFromItemList(itemIds) {
      const images = []

      for (const itemId of itemIds) {
        images.push(
          `${process.env.VUE_APP_URL}/Handlers/ImageHandler.ashx?TableName=Rental.Item&ItemID=${itemId}`
        )
      }

      return images
    },
    randomNumber: function() {
      return Math.floor(Math.random() * (11 - 99 + 1) + 99)
    },
    toItem: function() {
      if (!this.$route.path.includes(this.item.Slug)) {
        this.$router.push({
          path: this.routePath
        })
      }
    }
  }
}
</script>
<style scoped lang="scss">
@media (min-width: 992px) {
  .card-product:hover:not(.card-static) {
    box-shadow: 0 0.3rem 1.525rem -0.375rem rgba(0, 0, 0, 0.1);
    z-index: 10;
  }
}
.card-product {
  border: none;
  height: 100% !important;
}
.card-product:hover {
  cursor: pointer;
}

.card-footer {
  background-color: white;
  a {
    padding: 10px !important;
    font-size: 20px;
    line-height: 24px;

    font-family: 'DecimaPro-bold';

    text-decoration: none;
  }
}

.text-accent {
  color: #4e54c8 !important;
}

.product-title {
  min-height: 50px;
}

h3 {
  font-size: 0.875rem !important;
  a {
    color: #373f50 !important;
  }
  strong {
    color: #373f50 !important;
  }
}
.product-brand {
  transition: color 0.25s ease-in-out;
  color: #7d879c;
  text-decoration: none !important;
  font-size: 0.75rem !important;
}
.card-img-top {
  min-height: 306.66px;
}
@media (min-width: 992px) {
  .card-product:hover:not(.card-static) .card-body-hidden {
    opacity: 1;
    visibility: visible;
  }
}
</style>
