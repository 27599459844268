import { render, staticRenderFns } from "./Item.vue?vue&type=template&id=680a9053&scoped=true&"
import script from "./Item.vue?vue&type=script&lang=js&"
export * from "./Item.vue?vue&type=script&lang=js&"
import style0 from "./Item.vue?vue&type=style&index=0&id=680a9053&prod&scoped=true&lang=scss&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "680a9053",
  null
  
)


    import installComponents from "!/builds/rentmagic/rentmagic.website.vue.vlaams-brabant/node_modules/bootstrap-vue-loader/lib/runtime/installComponents.js"
    import {BImg} from 'bootstrap-vue'
    installComponents(component, {BImg})
    

export default component.exports